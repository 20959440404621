<template>
    <div class="footer_box">
        <div class="footer_content">
            <div class="single_box">
                <div class="single_title">联系方式</div>
                <div class="single_text">客服热线</div>
                <div class="single_number">19308094768</div>
                <div class="single_text">邮箱</div>
                <div class="single_number">2068086200@qq.com</div>
                <div class="single_text">地址</div>
                <div class="single_number">成都市天府二街泰然环球时代C座601</div>
            </div>
            <div class="single_box">
                <div class="single_title">友情链接</div>
                <div class="single_text">生鲜电商</div>
                <div class="single_text">直播交友</div>
                <div class="single_text">猪八戒店铺</div>
            </div>
            <div class="single_box">
                <div class="single_title">权威认证</div>
                <div class="single_text">3A级信用企业认证</div>
                <div class="single_text">软件企业证书</div>
                <div class="single_text">20+计算机软件著作权</div>
                <div class="single_text">阿里云授权</div>
                <div class="single_text">腾讯云授权</div>
            </div>
        </div>
        <div class="footer_des">
            <span @click="bindWebsite">蜀ICP备18034679号-4</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'IndexPage',
    components: {

    },
    mixins: [],
    props: {
        tabIndex: {
            type: Number,
            default: 1
        }
    },
    data() {
        return {

        }
    },
    computed: {

    },
    watch: {

    },
    mounted() {

    },
    methods: {
        bindWebsite(){
            window.open("https://beian.miit.gov.cn/","_blank")
        }
    }
};
</script>

<style lang='scss' scoped>
@mixin flex($a: row,
    $b: wrap,
    $c: flex-start,
    $d: flex-start) {
    display: flex;
    flex-direction: $a;
    flex-wrap: $b;
    justify-content: $c;
    align-items: $d;
}

.footer_box {
    width: 100%;
    height: 409px;
    background: #110E20;
    box-sizing: border-box;
    padding: 76px 280px 36px 280px;

    .footer_content {
        width: 100%;
        @include flex(row, nowrap, space-between, flex-start);
        flex: 1;

        .single_box {
            .single_title {
                font-size: 20px;
                line-height: 28px;
                color: #FFFFFF;
                margin-bottom: 12px;
            }

            .single_text {
                font-size: 14px;
                line-height: 20px;
                color: #CCCCCC;
                margin-bottom: 12px;
            }

            .single_number {
                font-size: 14px;
                line-height: 20px;
                color: #CCCCCC;
                margin-top: 4px;
            }
        }
    }

    .footer_des {
        margin-top: 86px;
        font-size: 14px;
        line-height: 20px;
        color: #CCCCCC;
        text-align: center;
        span{
            cursor: pointer;
        }
    }
}
</style>
 